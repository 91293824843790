import { formatAsRub, numberWithSpaces, processFormatInput, smartToFixed } from '@/common/misc';

export const budgetDataFilters = {
    fToFixed(s:string|number){
        if(typeof s == 'string' && Number.isNaN( parseInt(s) )) return s;
        return smartToFixed(processFormatInput(s), 2);
    },
    fSpaces(s:string|number){
        if(typeof s == 'string' && Number.isNaN( parseInt(s) )) return s;
        return numberWithSpaces(processFormatInput(s));
    },
    fReplaceIfNegative(s:string|number){
        if(typeof s == 'string' && Number.isNaN( parseInt(s) )) return s;
        const p = processFormatInput(s);
        if(Number.isNaN(p)) return '---';
        return p >= 0 ? p : '---';
    },
    fAsRub(s:string|number){
        if(typeof s == 'string' && Number.isNaN( parseInt(s) )) return s;
        const p = processFormatInput(s);
        if(Number.isNaN(p)) return '---';
        return formatAsRub(processFormatInput(s),2);
    },
    fPercent(s:string|number){
        if(typeof s == 'string' && Number.isNaN( parseInt(s) )) return s;
        const p = processFormatInput(s);
        if(Number.isNaN(p)) return '---';
        return s+"%";
    },
    fRound(s:string|number){
        if(typeof s == 'string' && Number.isNaN( parseInt(s) )) return s;
        const p = processFormatInput(s);
        if(Number.isNaN(p)) return '---';
        return Math.round(p);
    }
}