
import Vue from 'vue'
import ChannelManager from '@/common/classes/channel.manager';
import { mapGetters } from 'vuex';
import { Expense } from '@/common/classes/expenses';
import { unitChartData, clientChartData, monthChartData, rangeChartData} from '@/common/classes/chart.methods';
import { ProductModel } from '@/common/classes/models';
import ChartMethods from '@/common/classes/chart.methods';
import taxUtils from '@/common/classes/tax.utils';
export default Vue.extend({
  name: 'chartMethods',
  computed: {
    unitExpenseCost():number{ 
      const unitExpenses = this.product.expenses.filter((e:Expense)=>e.type===0) as Expense[]
      const expenseCost = unitExpenses.reduce((acc,cur)=> cur.countCost() + acc, 0);
      const earnings = this.unitEarnings;
      const taxCost = taxUtils.countTaxCostForExpenseType(0, earnings, earnings - expenseCost);
      return taxCost + expenseCost;
    },
    clientUnitExpenseCost():number{
      return this.unitExpenseCost * this.apc / this.clientsPerSalesUnit;
    },
    monthlyExpenseCost():number{
      const constExpenses = this.product.expenses.filter((e:Expense)=>e.type===1) as Expense[]
      return constExpenses.reduce((acc,cur)=>{acc+=cur.countCost(); return acc;},0)
    },
    annualExpenseCost():number{
      const onetimeExpenses = this.product.expenses.filter((e:Expense)=>e.type==2) as Expense[]
      return onetimeExpenses.reduce((acc,cur)=>{acc+=cur.countCost(); return acc;},0);
    },
    // earngingsFromClient():number{
    //   return this.avgCheck * ( (ChannelManager.getTotalSales() / ChannelManager.getTotalTargetMonthlySales() ) || 1 )
    // },
    earngingsFromClient():number{
      return this.unitEarnings * this.apc / this.clientsPerSalesUnit;
    },
    ltv():number{ return this.unitProfitNoMarketing * this.apc / this.clientsPerSalesUnit; },
    clientsPerSalesUnit():number{ return this.product.clientsPerSalesUnit },
    cac():number{ return ChannelManager.getAvgCac() || 0  },
    apc():number{ return this.product.getApc() },
    singleSaleCost():number{return ChannelManager.getAvgSingleSaleCost()||0 },
    cleanMarginSingleClient():number{return this.ltv-this.cac},
    //Is multiplied by clientsPerSalesUnit
    unitEarnings():number{ return ChartMethods.getUnitEarnings(); },
    unitProfitNoMarketing():number{return this.unitEarnings - this.unitExpenseCost || 0;},
    unitProfitCac():number{return this.unitEarnings - this.unitExpenseCost - this.cac || 0},
    unitProfit():number{return this.unitEarnings - this.unitExpenseCost - this.singleSaleCost || 0},
    unitMonthProfit():number{return this.unitProfit * ChannelManager.getTotalSales() || 0},
    /**
     * @returns Unit & marketing expenses for a month
     */
    unitMarketingMonthExpense():number{
      return (this.clientUnitExpenseCost + this.cac) * ChannelManager.getTotalTargetMonthlySales() || 0
    },
    unitsTillEven():number{ return this.monthlyExpenseCost / this.unitProfit || 0},
    monthlyEarnings():number{ return this.earngingsFromClient * ChannelManager.getTotalTargetMonthlySales() || 0},
    monthlyTaxCost():number{ return taxUtils.getConstTaxCost(this.monthlyProfitNoTax); },
    monthlyProfit():number{ return this.monthlyProfitNoTax - this.monthlyTaxCost || 0 },
    monthlyProfitNoTax():number{ return this.monthlyProfitNoConst - this.monthlyExpenseCost || 0 },
    monthlyProfitNoConst():number{ return this.monthlyEarnings - this.unitMarketingMonthExpense },
    monthsTillEven():number{ return this.annualExpenseCost/this.monthlyProfit || 0 },
    annualEarnings():number{ return this.monthlyEarnings*12 || 0},
    annualProfit():number{ return this.monthlyProfit * 12 - this.annualExpenseCost || 0; },
    // threeYearEarnings():number{ return this.monthlyEarnings * 36 || 0 },
    // threeYearProfit():number{ return this.monthlyProfit * 36 - this.annualExpenseCost || 0 },
    unitChartData():any{ return unitChartData.apply(this); },
    clientChartData():any{ return clientChartData.apply(this); },
    monthChartData():any{ return monthChartData.apply(this); },
    rangeChartData():any{ return rangeChartData.apply(this); },
    productChartMonthRange:{
      get():number{ return this.renderData.productChartMonthRange },
      cache: false,
    },
    rangeProfit():number{ return this.productChartMonthRange * this.monthlyProfit - this.annualExpenseCost },
    rangeUnitExpensesCost():number{ return this.productChartMonthRange * this.unitMarketingMonthExpense },
    rangeProfitNoConst():number{ return this.productChartMonthRange * (this.monthlyEarnings - this.unitMarketingMonthExpense) },
    rangeProfitNoOnetimeTax():number{ return this.rangeProfitNoConst - this.rangeConstExpensesCost; },
    rangeProfitNoOnetime():number{ return this.rangeProfitNoOnetimeTax - this.rangeTaxCost; },
    rangeTaxCost():number{ return taxUtils.countTaxCostForExpenseType(1, this.rangeEarnings, this.rangeProfitNoOnetimeTax) },
    rangeConstExpensesCost():number{ return this.productChartMonthRange * this.monthlyExpenseCost },
    rangeOnetimeExpensesCost():number{ return this.annualExpenseCost },
    rangeEarnings():number{ return this.productChartMonthRange * this.monthlyEarnings },
    // threeYearChartData():any {return threeYearChartData.apply(this); },
    expenses():Expense[]{ return this.product.expenses; },
    avgCheck():number{ return this.product.avgCheck; },
    bizModel():number{ return this.product.bizModel; },
    ...mapGetters(['product','channels','renderData'])
  }
})
