






















































































































































































import Vue from 'vue';
// import BarChart from '@/components/constructor-product-tab/BarChart.vue';
// import Collapsible from '@/components/elements/Collapsible.vue';
import ChartMethods from '@/components/mixins/ChartMethods.vue';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import { mapGetters } from 'vuex';
import { SET_COST_ERRORS } from '@/store/mutations';
import channelManager from '@/common/classes/channel.manager';
import ProductInfoComputed from '@/components/constructor-product-tab/ProductInfoComputed.vue';
import { getLinkToProject, smartToFixed } from '@/common/misc';
import BudgetComputed from '@/components/constructor-budget-tab/BudgetComputed.vue';
// import LineChart from '@/components/constructor-budget-tab/LineChart.vue';
import budget from '@/common/classes/budget';
import html2canvas from 'html2canvas';
import * as download from 'downloadjs';
import TariffService from '@/common/tariff.service';
import { detect } from 'detect-browser';
import { LoadProjectDTO } from '@/common/classes/models';

export default Vue.extend({
    name: 'productInfo',
    mixins: [ChartMethods,MiscMixin],
    components: {
        BarChart: () => import('@/components/constructor-product-tab/BarChart.vue'),
        // Collapsible,
        // IndustrySelect,
        // BizModelSelect,
        ProductInfoComputed,
        BudgetComputed,
        LineChart: () => import('@/components/constructor-budget-tab/LineChart.vue'),
    },
    data(){
        return{
            collapse: {
                chart: false,
                computed: false,
                errors: false
            },
            curChart: 0,
            channelManager,
            switchingChart: false,
            budget,
            budgetChartType: 0,
            //Used in ChartMethods.vue
            inputData: { budgetChartMonthRange: 12, budgetAccChartMonthRange: 12 },
            copyProjClicked: false,
        }
    },
    watch: {
        // async showBudgetChart(showBudget){
        //     if(!showBudget){ 
        //         await Vue.nextTick();
        //         (this as any).$refs.productInfoComputed.$forceUpdate();
        //     }
        // },
        curChart(chartIndex){
            //Set budgetChartType to acc(1) if current chartIndex is 4
            this.budgetChartType = chartIndex == 5 ? 1 : 0;
        },
        disabledCharts(v:number[]){
            if(v.includes(this.curChart)) this.curChart = 0;
        },
    },
    async mounted(){
        for (let i = 1; i < 6; i++) {
            this.curChart = i;
            await this.$nextTick();
        }
        this.curChart = 0;
    },
    methods: {
        async exportImage(type:'computed'|'chart'):Promise<void>{
            let el:HTMLElement|undefined = undefined;
            let filename:string|undefined = undefined;
            // const increaseProductInfoSize = () => {
            //     const productInfo:HTMLElement = this.$refs.productInfoContainer;
            //     productInfo.style.width = '350px';
            //     await new Promise((r)=>setTimeout(r,200));
            //     productInfo.style.removeProperty('width');
            // }
            if(!this.isAbleToExportImages) {
                console.error('User is unable to export images');
                return;
            }
            if(type == 'computed') {
                el = this.$el.querySelector('.product-computed-block')||this.$el.querySelector('.budget-computed-block');
                filename = 'project-computed';
            }
            if(type == 'chart') {
                const os = detect()?.os;
                if( os == 'Mac OS' || os == 'iOS' ){
                    const chartCanvas = this.$el.querySelector('#line-chart')||this.$el.querySelector('#bar-chart');
                    this.downloadCanvasToFile(chartCanvas, {filename:'project-chart', append: false})
                    return;
                }else {
                    el = this.$el.querySelector('#chart-container');
                    filename = 'project-chart';
                }
            }
            if(!el || !filename) {
                console.warn({msg: 'Insufficient data for image export', el, filename, type});
                return;
            }
            this.downloadElToFile(el, filename);
        },
        async downloadElToFile(el:any, filename:string):Promise<void> { 
            console.log({el, filename});
            const canvas = await html2canvas(el, {scale: 2});
            console.log({msg:'done',canvas});
            return this.downloadCanvasToFile(canvas, {filename});
        },
        async downloadCanvasToFile(canvas:HTMLCanvasElement, options:{filename?:string,append?:boolean}={filename:'image',append:true}):Promise<void> {
            const {filename, append} = options;
            
            console.log({filename, append, canvas})
            if(append) this.$el.appendChild(canvas);
            try { 
                console.log('canvas.toDatAUrl')
                let quality =  1;
                if( detect()?.name == 'safari' ) quality = 0.2;
                const dataURl = canvas.toDataURL('image/png',quality);
                console.log('done low quality')
                const dataUrl2 = canvas.toDataURL('image/png');
                console.log('done high quality')
                console.log({msg:'download start', dataURl, dataUrl2})
                download.default(dataURl, filename); 
                console.log('download end')
            } 
            catch (e) { 
                console.log('Catched an error, '+e);
                console.error(e); 
            }
            if(append) (this.$el as HTMLElement).removeChild(canvas);
        },
        afterLoad(loadDTO:LoadProjectDTO){
            this.switchCurChart( loadDTO.chart || 0 );
        },
        deleteCostErrors(){
            this.$store.commit(SET_COST_ERRORS,[]);
        },
        //Prevents page scroll
        async switchCurChart(index:number){
            const activeChartSelect:HTMLSelectElement = this.$refs.activeChartSelect;
            const maxChartIndex = activeChartSelect.options.length;
            if(maxChartIndex <= index || index < 0){ 
                console.warn({msg:'Provided curChart index is invalid', index, maxChartIndex});
                return;
            }
            this.switchingChart = true;
            await Vue.nextTick();
            this.curChart = index;
            activeChartSelect.selectedIndex = index;
            await Vue.nextTick();
            this.switchingChart = false;
        },
        chartTooltipByIndex(this:any,index:number){
            const t = this.tltpText;
            switch(index){
                case 0: return t('chartUnit');
                case 1: return t('chartClient');
                case 2: return t('chartMonth');
                case 3: return t('chartRange');
                case 4: return t('moneyFlow');
                case 5: return t('moneyFlowAccum');
            }
        }
    },
    computed: {
        isAbleToExportImages():boolean{
            if(!this.isAuthenticated) return false;
            return TariffService.checkUserHasTrait(TariffService.getTraitTypes().PROJECT_POWER_POINT_EXPORT,this.user);
        },
        isAbleToShareProject():boolean{
            if(!this.projectData) return false;
            return !!getLinkToProject(this.projectData);
        },
        showBudgetChart():boolean{
            return this.curChart == 4;
        },
        showAccBudgetChart():boolean{
            return this.curChart == 5;
        },
        totalSales():number{
            return channelManager.getTotalSales();
        },
        disabledCharts(this:any):number[]{
            const ret = [];
            if(!this.showCustomerChart)ret.push(1);
            if(!this.showMonthChart)ret.push(2);
            if(!this.showAnnualChart)ret.push(3,4);
            return ret;
        },
        apc():number{
            return smartToFixed( this.product.getApc(), 2 );
        },
        monthlySales():number{
            return this.product.retention>0?this.totalSales||0:channelManager.getTotalTargetMonthlySales()||0;
        },
        unitProfitPercent(this:any):number{
            const {product,unitProfitNoMarketing} = this;
            const {avgCheck} = product;
            return 100/(avgCheck/unitProfitNoMarketing);
        },
        showUnitChart(this:any):boolean{
            return true;
        },
        showCustomerChart(this:any):boolean{
            return this.showUnitChart 
        },
        showMonthChart(this:any):boolean{
            return this.showUnitChart 
                && !!channelManager.getTotalTargetMonthlySales()
                && channelManager.getTotalTargetMonthlySales() > 0 
                //&& this.monthlyExpenseCost;
        },
        showAnnualChart(this:any):boolean{
            return this.showMonthChart /*&& this.annualExpenseCost*/;
        },
        ...mapGetters(['isAuthenticated','projectData','costErrors','product','channels','isLoadingProject','renderData','user'])
    }
})
