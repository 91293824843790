






































































































































































































































import Vue from 'vue';
import {smartToFixed,formatAsRub,numberWithSpaces} from '@/common/misc';
import { mapGetters } from 'vuex';
import channelManager from '@/common/classes/channel.manager';
import ChartMethods from '@/components/mixins/ChartMethods.vue';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import ProductManager from '@/common/classes/product.manager';
import { ProductModel } from '@/common/classes/models';
import taxUtils from '@/common/classes/tax.utils';
import { ProductTax } from '@/common/types/product.types';
import { formComputedTooltip } from '@/common/tooltips';

export default Vue.extend({
    name: 'productInfoComputed',
    mixins: [ChartMethods,MiscMixin],
    props: {
        showBudgetChart: {type:Boolean},
        curChart: {type:Number},
    },
    watch: {
        async curChart(curChart:number){
            await Vue.nextTick();
            this.$forceUpdate();
        },
        async curTax(){
            await Vue.nextTick();
            this.$forceUpdate();
        },
    },
    methods: {
        smartToFixed,
        formatAsRub,
        numberWithSpaces,
        chartTltp(key:string){
            return formComputedTooltip(key, this.curChart);
        },
        async onBind(){
            await Vue.nextTick();
            this.$forceUpdate();
        }
    },
    computed: {
        curChartColors(){
            const {unit,client,month,region} = ProductManager.getProductInfoChartDataColors();
            switch(this.curChart){
                case 0: return unit;
                case 1: return client;
                case 2: return month;
                case 3: return region;
                default: return undefined;
            }
        },
        curTax():ProductTax{
            return taxUtils.getCurrentTax();
        },
        clientVS(this:any){
            const { clientsPerSalesUnit } = this.product as ProductModel;
            return{
                earnings: this.earngingsFromClient,
                unitExpenseCost: this.unitExpenseCost * this.apc / clientsPerSalesUnit,
            }
        },
        profitability(this:any):string{
            const profitability = (this.monthlyProfit/this.monthlyEarnings*100) || 0;
            if(!Number.isFinite( profitability )) return '---';
            return this.numberWithSpaces( this.smartToFixed( profitability, 2) ) + ' %';
        },
        totalSales():number{
            return channelManager.getTotalSales();
        },
        targetMonthlySales():number{
            return channelManager.getTotalTargetMonthlySales();
        },
        displayMonthsTillEven(this:any):string{
            const {monthsTillEven} = this;
            return (this.unitMonthProfit>0 && monthsTillEven > -1 && monthsTillEven < 100 ? Math.ceil(monthsTillEven)+" мес." : "НЕ ОКУПАЕТСЯ");
        },
        unitProfitPercent(this:any):number{
            const {unitProfitNoMarketing, unitEarnings} = this;
            return 100/(unitEarnings/unitProfitNoMarketing);
        },
        ...mapGetters(['isAuthenticated','projectData','costErrors','product'])
    },
    filters: {
        smartToFixed,
        formatAsRub,
        numberWithSpaces,
    },
    //TODO: Refactor all computed fields (have a single object that's used for chart, computed list & contains color data)
    directives: {
        'update-on-show':{
            bind:(el,binding)=>binding.value(),
        },
        'computed-bg-style':{
            unbind(el){
                el.style.removeProperty('background-color');
                // el.style.removeProperty('display');
            },
            update(el,binding) {
                type ColorDirectiveData = {
                    color:
                        {pos:string,neg:string}|
                        {computed:string,chart:string}|
                        string,
                    value:number
                };
                const directiveData:ColorDirectiveData = binding.value;
                const {color,value} = directiveData;
                // if(value == 0) { 
                //     el.style.display = 'none'; 
                //     return; 
                // }
                if(color == undefined) { 
                    // el.style.removeProperty('display');
                    el.style.removeProperty('background-color');
                    return; 
                }
                // if(color == undefined) { el.style.removeProperty('background-color'); return;}
                if(typeof color == 'string'){ el.style.backgroundColor = color; return; }
                const colorKeys = Object.keys(color);
                let bgColor:string;
                if(colorKeys.includes('pos')) bgColor = (color as any)[value>0?'pos':'neg'];
                else if(colorKeys.includes('computed')) bgColor = (color as any).computed;
                else{
                    console.warn({msg:'unrecognized directive color object',color,colorKeys});
                    return;
                }
                el.style.backgroundColor = bgColor;
            }
        }
    }
})
