





























import Vue from 'vue';
import budget from '@/common/classes/budget';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import { budgetDataFilters } from '@/common/unit-economy/budget/budget.filters';
import { formComputedTooltip } from '@/common/tooltips';

export default Vue.extend({
    name: 'budgetComputed',
    mixins: [MiscMixin],
    props: {
        showBudgetChart: {type:Boolean},
        showAccBudgetChart: {type:Boolean},
        curChart: {type:Number}
    },
    data(){
        return{
            budget
        }
    },
    methods: {
        monthForVal(v:any){
            return typeof v == 'number' && v > 0 && !Number.isNaN(v) ? 'мес':'';
        },
        chartTltp(key:string){
            return formComputedTooltip(key, this.curChart);
        },
    },
    computed: {
        showBreakevenPoint():boolean{
            return !Number.isNaN( budget.breakevenPoint() ) && budget.breakevenPoint() > -1 
        },
    },
    filters: {
        ...budgetDataFilters,
    }
})
