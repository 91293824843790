import store from "@/store";

import {ISEVal, IVal} from '@/common/interfaces';
import { ChartData, ChartOptions } from 'chart.js';
import { formatAsRub, numberWithSpaces } from '@/common/misc';

import ProductManager from '@/common/classes/product.manager';

const colors = ProductManager.getProductInfoChartDataColors();

class ChartMethods {
    getAvgCheck():number{ return store.state.product.avgCheck; }
    getclientsPerSalesUnit():number{ return store.state.product.clientsPerSalesUnit; }
    getUnitEarnings():number{ return this.getAvgCheck() * this.getclientsPerSalesUnit(); }
}

export function processFloatValues(vs:ISEVal[]){
    const floatData = vs.reduce((acc:number[][],cur)=>[...acc,cur.d],[])
    const floatLabels = vs.map((b)=>b.n)
    const floatColors = vs.map((b)=>b.c)
    return {
        data: {
            labels: floatLabels,
            datasets:[{ backgroundColor: floatColors, data: floatData, label: '', barPercentage: 0.97, categoryPercentage: 0.97 }]
        } as ChartData,
        options: { 
            legend: false, 
            scales: { 
                xAxes: [{ 
                    display: false,
                    stacked: true, 
                    ticks: {
                        autoSkip: false, 
                        maxRotation:90,
                        minRotation:90,
                        callback: function(value:string|number,index:number,values:number[]|string[]){
                            if(typeof value == 'string') return value;
                            return numberWithSpaces( value );
                        },
                    }, 
                    gridLines: {
                        color: 'rgba(0,0,0,0)'
                    }
                }],
                yAxes: [{ 
                    stacked: true, 
                    ticks: {
                        suggestedMin: 0,
                        beginAtZero: true,
                        callback: function(value:string|number,index:number,values:number[]|string[]){
                            if(typeof value == 'string') return value;
                            return numberWithSpaces( value );
                        },
                    }
                }]
            },
            animation: { duration: 0 },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem:any,data:any){
                        const d = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                        return formatAsRub(d[1]-d[0],2);
                    }
                }
            }
        } as ChartOptions
    }
}

//TODO: Rewrite using zmg
export function unitChartData(this: any){
    const clrs = colors.unit;
    const earningsName = this.clientsPerSalesUnit == 1 ? 'Средний чек' : 'Выручка на группу';
    const earnings = { v:this.unitEarnings, n: earningsName, c:clrs.avgCheck.chart };
    const {unitProfit,unitProfitNoMarketing} = this;
    const isProfiting = this.unitProfit > 0;
    const floatVS = [ ] as {v:any,n:string,c:string}[];

    if(this.unitExpenseCost != 0) floatVS.push({ v:this.unitExpenseCost, n:'Переменные затраты', c:clrs.unitExpenseCost.chart })
    const hasMarketing = unitProfit && unitProfit != unitProfitNoMarketing;
    // console.log({hasMarketing,unitProfit,unitProfitNoMarketing})

    const unitProfitNoMarketingData = {v:unitProfitNoMarketing, n:'Маржа без учета маркетинга',c:unitProfitNoMarketing > 0 ? clrs.unitProfitNoMarketing.pos:clrs.unitProfitNoMarketing.neg};
    if(!hasMarketing) floatVS.push(unitProfitNoMarketingData)
    if(hasMarketing){ 
        floatVS.push(
            {v:unitProfitNoMarketing-unitProfit,n:'Стоимость 1 продажи',c:clrs.singleSaleCost.chart},
            {v:unitProfit, n:'Чистая Маржа', c:isProfiting ? clrs.unitProfit.pos: clrs.unitProfit.neg},
        );
    }
    const floatDataset = floatBarDataset(floatVS,earnings);
    if(hasMarketing){
        const unitProfitNoMarketingDataset = {
            d:[0,unitProfitNoMarketing],
            n:'Маржа без учета маркетинга',
            c:unitProfitNoMarketing>0?clrs.unitProfitNoMarketing.pos:clrs.unitProfitNoMarketing.neg
        }
        const pop1 = floatDataset.pop() as ISEVal;
        const pop2 = floatDataset.pop() as ISEVal;
        floatDataset.push(unitProfitNoMarketingDataset,pop2,pop1);
    }
    const processed = processFloatValues(floatDataset);
    return processed;
}
export function clientChartData(this:any){
    const clrs = colors.client;
    const {ltv,cac,cleanMarginSingleClient,earngingsFromClient,clientUnitExpenseCost} = this;
    const earnings = {v:earngingsFromClient, n:'Выручка от 1 нового клиента', c:clrs.oneClientEarnings.chart};
    const floatVS = [
        {v:cleanMarginSingleClient,n:'Чистая маржа',c:cleanMarginSingleClient > 0 ? clrs.unitProfit.pos : clrs.unitProfit.neg},
    ] as IVal[];
    if(cac!=0){
        floatVS.unshift({v:cac,n:'САС',c:clrs.cac.chart});
        if(ltv!=0)
            floatVS.unshift({v:ltv, n:'LTV', zmg:true, c:ltv > 0 ? clrs.ltv.pos : clrs.ltv.neg});
    }

    if(clientUnitExpenseCost!=0)
        floatVS.unshift({v:clientUnitExpenseCost,n:'Переменные затраты',c:clrs.unitExpenseCost.chart});
    
    const processed = processFloatValues(floatBarDataset(floatVS,earnings));
    return processed;
}
export function monthChartData(this:any){
    const clrs = colors.month;
    const earnings = {v:this.monthlyEarnings, n:'Выручка в месяц',c:clrs.monthEarnings.chart}
    const isProfiting = this.monthlyProfit > 0
    const floatVS = [];
    if(this.unitMarketingMonthExpense!=0) floatVS.push({v:this.unitMarketingMonthExpense, n:'Переменные затраты и маркетинг',c:clrs.unitExpCostAndMarketing.chart});
    if(this.monthlyExpenseCost > 0) {
        floatVS.push({v:this.monthlyProfitNoConst, zmg:true, n:'Маржа за месяц', c:this.monthlyProfitNoConst>0?clrs.monthlyProfitNoConst.pos:clrs.monthlyProfitNoConst.neg});
        floatVS.push({v:this.monthlyExpenseCost, n:'Постоянные затраты', c:clrs.constExpenses.chart});
    }
    const monthlyProfitNoTax = this.monthlyProfitNoTax;
    const constTax = this.monthlyTaxCost;
    floatVS.push({v:monthlyProfitNoTax, n:'Прибыль', zmg:constTax != 0,c:isProfiting?clrs.monthProfits.pos:clrs.monthProfits.neg})
    
    if(constTax != 0) {
        floatVS.push({v:constTax, n:'Налог УСНО Д-Р', c:clrs.taxCost.chart});
        floatVS.push({v:this.monthlyProfit, n:'Чистая прибыль', c:isProfiting ? clrs.monthCleanProfit.pos : clrs.monthCleanProfit.neg});
    }
    
    const processed = processFloatValues(floatBarDataset(floatVS,earnings))
    //if(!isProfiting) processed.data.datasets.push({backgroundColor:[colors.unitProfitNoMarketing.neg],label:'Убыток',data:[[Math.abs(this.monthlyProfit),0]]})
    return processed
}
export function rangeChartData(this:any){
    const range = this.renderData.productChartMonthRange;
    const clrs = colors.region;
    // const range = 12;
    const monthlyEarnings = this.monthlyEarnings*range;
    const earnings = {v:monthlyEarnings, n:'Выручка',c:clrs.rangeEarnings.chart};
    const floatVS = [];
    const unitMarketingMonthExpense = this.unitMarketingMonthExpense*range;
    const rangeProfitNoConst = this.rangeProfitNoConst; // monthlyEarnings - unitMarketingMonthExpense
    if(unitMarketingMonthExpense) {
        floatVS.push({v:unitMarketingMonthExpense,n:'Переменные затраты и маркетинг',c:clrs.unitExpCostAndMarketing.chart});
        const {pos,neg} = clrs.rangeProfitNoConst;
        floatVS.push({v:rangeProfitNoConst,n:'Маржа',zmg:true,c:rangeProfitNoConst > 0 ? pos:neg});
    }
    const monthlyExpenseCost = this.monthlyExpenseCost*range;
    const rangeProfitNoOnetime = this.rangeProfitNoOnetime; //  rangeProfitNoConst - monthlyExpenseCost
    const constTax = this.rangeTaxCost;
    if(monthlyExpenseCost > 0 || constTax > 0) {
        floatVS.push({v:monthlyExpenseCost + constTax,n:constTax != 0 ? 'Постоянные затраты + налоги' : 'Постоянные затраты',c:clrs.constExpenses.chart});
        
        const {pos,neg} = clrs.rangeProfitNoOnetime;
        floatVS.push({v:rangeProfitNoOnetime,n:constTax > 0 ? 'Чистая прибыль' : 'Прибыль',zmg:true,c:rangeProfitNoOnetime > 0 ? pos:neg});
    }
    const annualExpenseCost = this.annualExpenseCost;
    if(annualExpenseCost > 0) floatVS.push({v:annualExpenseCost,n:'Разовые затраты',c:clrs.onetimeExpenses.chart});
    
    const invRet = this.monthlyProfit*range-this.annualExpenseCost;
    const isReturning = invRet > 0;
    
    floatVS.push({v:invRet,n:'Возврат на инвестиции',c:isReturning?clrs.investmentReturn.pos:clrs.investmentReturn.neg});

    return processFloatValues(floatBarDataset(floatVS,earnings));
}
function floatBarDataset(vs:IVal[],main:IVal):ISEVal[]{
    if(vs.length == 0) return [];
    const total = vs.reduce((a:number,c:IVal)=>a+c.v,0)
    vs.unshift(main)
    const retVS:ISEVal[] = []
    for (let i = 0; i < vs.length; i++) {
        const e = vs[i]
        const checkIsMG = (v:IVal)=>v.zmg!=undefined && !!v.zmg;
        const isMG = checkIsMG(e);
        const baseStart = i == 0 ? 0 : vs.slice(i+1).reduce((a,c)=>a+(checkIsMG(c)?0:c.v),0);
        const start = isMG ? 0 : baseStart;
        const end = isMG ? e.v : e.v+start;
        retVS.push({d:[start,end],...e});
    }
    return retVS
}

export default new ChartMethods();